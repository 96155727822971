import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react"
import loadScript from "load-script"

const TrackingContext = createContext({})

export function TrackingProvider({ children }) {
  const [isLoaded, setIsLoaded] = useState(false)
  const [tracker, setTracker] = useState({})
  const [provider, setProvider] = useState({})

  useEffect(() => {
    loadScript("/static/kissmetrics.js", (err) => {
      if (err) {
        console.log(err)
      }
    })
  }, [])

  useEffect(() => {
    window.onload = () => {
      if (window.KM) {
        setIsLoaded(true)
        setTracker(window._kmq)
        setProvider(window.KM)
      }
    }
  }, [])

  const track = useCallback(
    async (eventName, props = {}) => {
      if (isLoaded && "push" in tracker) {
        return new Promise((resolve, reject) => {
          try {
            tracker.push([
              "record",
              eventName,
              props,
              () => {
                resolve()
              },
            ])
          } catch (err) {
            console.log(err)
            reject(err)
          }
        })
      }
    },
    [isLoaded, tracker]
  )

  const identify = useCallback(
    (email) => {
      if (isLoaded && "push" in tracker) {
        if (provider.i() && provider.i().slice(-1) === "=") {
          try {
            tracker.push(["identify", email])
          } catch (err) {
            console.log(err)
          }
        }
      }
    },
    [isLoaded, tracker, provider]
  )

  const set = useCallback(
    (props, email = "") => {
      if (isLoaded && "push" in tracker) {
        if (provider.i() && provider.i().slice(-1) === "=" && email) {
          try {
            tracker.push(["identify", email])
          } catch (err) {
            console.log(err)
          }
        }
        try {
          tracker.push(["set", props])
        } catch (err) {
          console.log(err)
        }
      }
    },
    [isLoaded, tracker, provider]
  )

  return (
    <TrackingContext.Provider value={{ track, identify, set }}>
      {children}
    </TrackingContext.Provider>
  )
}

export function useTracking() {
  const context = useContext(TrackingContext)

  if (!context) {
    throw new Error("Tracking must be used within a TrackingProvider")
  }

  return context
}
