import React, { useState, useMemo } from "react"
import { useLocation } from "react-router-dom"

import { jobtitleData, revenuesData } from "../../../../../../i18n"

import { withNamespaces } from "react-i18next"
import { useForm } from "react-hook-form"
import InputMask from "react-input-mask"

import qs from "qs"

import { Box } from "@material-ui/core"

import * as patterns from "modules/form-validation/pattern"
import yup from "modules/form-validation"
import { loginRedirect, gtm, getOriginModuleByUrl } from "modules/helpers"
import { splitName } from "modules/helpers"
import { useClient } from "modules/hooks/login/client"
import auth from "modules/services/auth"
import { useTracking } from "modules/hooks/tracking"

import LottieLoading from "../../LottieLoading"

import AutoComplete from "../../AutoComplete"
import TextField from "../../TextField"
import Error from "../../../../Error"
import SingleCheckbox from "components/Form/Checkbox/SingleCheckbox"

import { CustomLabel } from "components/Form/Checkbox/style"
import {
  ContainerLogin,
  ContainerImages,
  Form,
  Button,
  RowText,
  SizedBox,
  RowSelects,
  SizedBoxSelect,
} from "../../../style"

const SignUp = ({ handleForm, t }) => {
  const { search } = useLocation()
  const queryString = useMemo(() => {
    let _search = search ? search : ""
    return qs.parse(_search.replace("?", ""))
  }, [search])

  const { track, identify } = useTracking()

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .matches(
        /^([A-Za-zÀ-ÖØ-öø-ÿ]{3,})+\s+([A-Za-zÀ-ÖØ-öø-ÿ\s]{2,})+$/,
        `${t("text_full_name_sign_up")}`
      )
      .required(),
    email: yup.string().email().required(),
    password: patterns.password,
    passwordAgain: yup
      .string()
      .required()
      .oneOf([yup.ref("password"), null], `${t("text_forgot_password_same")}`),
    jobtitle: yup.string().required(),
    revenues: yup.string().required(),
    phone: yup.string().required(),
    agree: yup.bool().oneOf([true], `${t("text_accept_terms_sign_up")}`),
  })

  const { register, handleSubmit, errors } = useForm({ validationSchema })

  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const { getQueryString, config, redirect, view } = useClient()

  const originModule = useMemo(
    () =>
      getOriginModuleByUrl(
        queryString.redirect_to
          ? queryString.redirect_to
          : queryString.redirect_uri
      ),
    [queryString.redirect_to, queryString.redirect_uri]
  )

  const onSubmit = async (data) => {
    setLoading(true)

    const revenues = revenuesData.find((revenuesDataList) =>
      revenuesDataList.label === data.revenues
        ? revenuesDataList.value
        : undefined
    )

    const company = {
      name: "",
      revenues: revenues ? revenues.value : null,
      market: null,
    }

    let jobtitle = jobtitleData.find((jobtitleDataList) =>
      jobtitleDataList.label === data.jobtitle
        ? jobtitleDataList.value
        : undefined
    )
    if (jobtitle === undefined) {
      jobtitle = data.jobtitle
    } else {
      jobtitle = jobtitle?.value
    }

    setError(null)

    delete data.revenues
    delete data.jobtitle

    try {
      const [firstName, lastName] = splitName(data.name)
      const response = await auth.post("/oauth2/signup" + getQueryString(), {
        ...data,
        company,
        jobtitle,
        firstName,
        lastName,
      })
      if (response)
        if (!response || !response.data || !response.data.message)
          throw new Error(`${t("text_error_create_account_sign_up")}`)
      if (view === "prime") gtm("login", data.email)
      const loginResponse = await auth.post(
        "auth/signin",
        {
          client_id: response.data.id,
          email: data.email,
          password: data.password,
        },
        { withCredentials: true }
      )

      if (!loginResponse || !loginResponse.data || !loginResponse.data.email)
        throw new Error(`${t("text_error_login_sign_up")}`)

      try {
        identify(loginResponse.data.email)
        await track("login", {
          originModule,
        })
      } catch (err) {
        console.log(err)
      }
      redirect()
    } catch (error) {
      console.log(error)
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        if (error.response.data.message.indexOf("11000") > -1)
          return setError(`${t("text_warning_forgot_password")}`)

        return setError(error.response.data.message)
      }
      setError(`${t("text_error_create_account_sign_up")}`)
    } finally {
      setLoading(false)
    }
  }

  const socialLoginTrackAndRedirect = async (provider) => {
    await track("login", {
      originModule,
      socialLoginProvider: provider,
    })
    loginRedirect(
      `${process.env.REACT_APP_AUTH_URI}auth/${provider}/${getQueryString()}`
    )
  }

  return (
    <>
      <ContainerLogin>
        <h1>{t("title_main_modal_sign_up")}</h1>
        <ContainerImages>
          <p>{t("subtitle_main_modal_social_media")}</p>
          <Box>
            <a
              href={`${
                process.env.REACT_APP_AUTH_URI
              }auth/facebook/${getQueryString()}`}
              onClick={() => {
                socialLoginTrackAndRedirect("facebook")
              }}
            >
              <img src="/img/facebook-icon.svg" alt="Logo Facebook" />
            </a>
            <a
              href={`${
                process.env.REACT_APP_AUTH_URI
              }auth/linkedin/${getQueryString()}`}
              onClick={() => {
                socialLoginTrackAndRedirect("linkedin")
              }}
            >
              <img src="/img/linkedin-icon.svg" alt="Logo Linkedin" />
            </a>
            <a
              href={`${
                process.env.REACT_APP_AUTH_URI
              }auth/google/${getQueryString()}`}
              onClick={() => {
                socialLoginTrackAndRedirect("google")
              }}
            >
              <img src="/img/google-icon.svg" alt="Logo Google" />
            </a>
          </Box>
          <p>{t("subtitle_main_modal_social_media")}</p>
        </ContainerImages>
        <Form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
          <TextField
            placeholder={t("placeholder_main_modal_name")}
            inputRef={register}
            label={t("input_main_modal_name")}
            name="name"
            errors={errors}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            placeholder={t("placeholder_main_modal_email")}
            inputRef={register}
            label={t("input_main_modal_email")}
            name="email"
            errors={errors}
          />
          <TextField
            placeholder={t("placeholder_main_modal_password_sign_up")}
            inputRef={register}
            label={t("input_main_modal_password")}
            name="password"
            type="password"
            errors={errors}
          />
          <TextField
            placeholder={t("placeholder_main_modal_confirm_password")}
            inputRef={register}
            label={t("input_main_modal_confirm_password_sign_up")}
            type="password"
            name="passwordAgain"
            errors={errors}
          />
          <RowSelects>
            <AutoComplete
              placeholder={t("placeholder_main_modal_select")}
              options={jobtitleData}
              getOptionLabel={(option) => option.label}
              getOptionSelected={(option, value) =>
                value?.value === option?.value
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t("placeholder_main_modal_select")}
                  label={t("select_main_modal_position")}
                  inputRef={register}
                  name="jobtitle"
                  errors={errors}
                />
              )}
            />
            <SizedBoxSelect />
            <AutoComplete
              placeholder={t("placeholder_main_modal_select")}
              options={revenuesData}
              getOptionLabel={(option) => option.label}
              getOptionSelected={(option, value) =>
                value?.label === option?.label
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("select_main_modal_company_size")}
                  placeholder={t("placeholder_main_modal_select")}
                  inputRef={register}
                  name="revenues"
                  errors={errors}
                />
              )}
            />
          </RowSelects>
          <InputMask mask="99 99999 9999" maskChar={null}>
            {() => (
              <TextField
                name="phone"
                placeholder="(XX) XXXXX-XXXX"
                label={t("input_main_modal_phone")}
                inputRef={register}
                errors={errors}
              />
            )}
          </InputMask>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              margin: "-8px 0",
            }}
          >
            {config?.privacy || config?.terms ? (
              <SingleCheckbox
                errors={errors}
                name="agree"
                register={register}
                defaultValue={true}
                label={
                  <CustomLabel
                    style={{ fontSize: "2vh", fontFamily: "Barlow" }}
                  >
                    <span>{t("check_main_modal_privacy_policy")} </span>
                    <a
                      style={{
                        textDecoration: "none",
                        fontFamily: "Barlow",
                        color: "rgb(30, 144, 255)",
                      }}
                      rel="noopener noreferrer"
                      target={"_blank"}
                      href="https://docs.startse.com/docs/politica-de-privacidade"
                    >
                      {t("link_main_modal_privacy_policy")}
                    </a>
                  </CustomLabel>
                }
              />
            ) : null}
          </div>

          <Error text={error} center />

          <Button type="submit">{t("button_continue")}</Button>
          <RowText>
            <p>
              {t("text_have_account")},{" "}
              <span onClick={handleForm}>{t("link_click_here")}</span>
            </p>
          </RowText>
          <SizedBox />
        </Form>
      </ContainerLogin>
      <LottieLoading fullLoading loading={loading} />
    </>
  )
}

export default withNamespaces()(SignUp)
